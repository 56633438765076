.container {display: block;width: 35px;height: 35px; line-height:35px; text-align:center; position: absolute;top: 13px; left: 10px; z-index: 550;cursor: pointer;}
.container:not(.home) { background: url(../Logo/images/logo_mo.svg) no-repeat center center; }
.container:before { font-family: 'jt-font'; }
.container.home:before { content: '\e90d';font-size: 20px;font-weight: normal; color:#222; }
.container.invert:before { color:#fff; filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.3));}
.container.stickleft {left:9px;}
:global(.ios) .container { top: 14px; }

@media (min-width: 1023px){
    .container { top: 22px; left: 17px;}
    .container:not(.home) { display: none; }
}
